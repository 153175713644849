import React, { useEffect } from 'react';

import { Field, Form } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { inputsFilterTrue } from '../../../global/Styles/inputs';

import Select from 'react-select';
import { setContratoInit, setTarifaInit, setTipoVehiculoInit } from '../../../../actions';
import { store } from '../../../../store';

const Filter = (props) => {
  const { getTipoVehiculo, getTarifaUnidad, getContrato } = props.endPoint;
  const { tipovehiculo, tarifaUnidad, setFilterUnidad, setFilterTipoVehiculo, contrato, setFilterContrato } = props.data;

  const handleSubmmit = () => {};

  const tipoVehiculoChange = (args, state) => {
    const field = state.fields['VEHICULO_TIPO_ID'];
    field.change(args[0].value);

    state.formState.submitFailed = true;

    //CAMBIA EL FILTRO.
    setFilterTipoVehiculo(args[0].value);
  };

  const unidadChange = (args, state) => {
    const field = state.fields['TARIFA_UNIDAD'];
    field.change(args[0].value);

    state.formState.submitFailed = true;

    //CAMBIA EL FILTRO.
    setFilterUnidad(args[0].value);
  };

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    field.change(args[0].value);

    state.formState.submitFailed = true;
    //CAMBIA EL FILTRO.
    setFilterContrato(args[0].value);
  };

  useEffect(() => {
    store.dispatch(setContratoInit());
    store.dispatch(setTipoVehiculoInit());
    store.dispatch(setTarifaInit());

    getTipoVehiculo();
    getTarifaUnidad();
    getContrato();
  }, []);

  const contratoOptions = contrato.contrato.map((row) => ({
    label: `${row.CONTRATO_NUMERO} - ${row.OPERACION_MODO_NOMBRE}`,
    value: row.CONTRATO_ID,
  }));

  console.log('contratoOptions', contratoOptions[0]);

  return (
    <Form
      onSubmit={handleSubmmit}
      initialValues={{}}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ tipoVehiculoChange, unidadChange, setCONTRATO_ID }}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmTaskFilters" />

          <div className="flex flex-row gap-2 pb-2 pr-2">
            <div className="flex-1 items-center gap-2 ">
              <h1 className="">Tipo de vehículo</h1>
              <Field name="VEHICULO_TIPO_ID">
                {({ input, meta }) => (
                  <>
                    {tipovehiculo.length > 0 && (
                      <Select
                        options={tipovehiculo}
                        defaultValue={tipovehiculo[0]}
                        name="selectNovedadTipoOptions"
                        onChange={form.mutators.tipoVehiculoChange}
                        menuPortalTarget={document.body}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                            ...styles,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: '20',
                          }),
                        }}
                      />
                    )}
                    <input
                      {...input}
                      type="hidden"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="flex-1 items-center gap-2 ">
              <h1 className="">Unidad</h1>
              <Field name="TARIFA_UNIDAD">
                {({ input, meta }) => (
                  <>
                    {tarifaUnidad.length > 0 && (
                      <Select
                        options={tarifaUnidad}
                        defaultValue={tarifaUnidad[0]}
                        name="selectNovedadTipoOptions"
                        onChange={form.mutators.unidadChange}
                        menuPortalTarget={document.body}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                            ...styles,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: '20',
                          }),
                        }}
                      />
                    )}
                    <input
                      {...input}
                      type="hidden"
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="flex-1 items-center gap-2 ">
              <h1 className="">Contrato</h1>
              <Field name="CONTRATO_ID">
                {({ input, meta }) => (
                  <>
                    {contratoOptions.length > 0 && (
                      <Select
                        options={contratoOptions}
                        defaultValue={contratoOptions[0]}
                        name="selectNovedadTipoOptions"
                        onChange={form.mutators.setCONTRATO_ID}
                        menuPortalTarget={document.body}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                            ...styles,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: '20',
                          }),
                        }}
                      />
                    )}
                    <input
                      {...input}
                      type="hidden"
                    />
                  </>
                )}
              </Field>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
export default Filter;
